table {
	font-size: 14px;
}

.hide {
    display: none;
}

/* CSS scroll-bar for the whole app. */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
	border-radius: 100px;
}
/* Track */
::-webkit-scrollbar-track {
	background:  #dcdcdc;
	border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(136, 136, 136, 0.829);
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.text-cut {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.marker-label {
	font-size: 12px !important;
	color: black !important;
	font-weight: bold;
	transform: translateY(-22px);
	background-color: white;
	padding: 0 4px;
	border-radius: 2px;
	white-space: pre-line;
}

.pf-c-form {
	--pf-c-form--GridGap: 14px !important;
}

.pf-c-form__group-label {
	padding-bottom: 2px  !important;
}

.MuiSpeedDialAction-staticTooltipLabel {
	width: 140px;
}
